<template>
  <v-container fluid>
    <v-row class="mt-2">
      <v-col cols="12" ref="tops">
        <p class="title">Adicionar Dependente</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <p class="title">
          Informações do titular:
        </p>
      </v-col>

      <v-col cols="12">
        <v-form ref="isValidFormHolderInfo" v-model="isValidFormHolderInfo">
          <v-row class="mx-1">
            <v-col cols="12" sm="6" md="3">
              <label class="label">CPF</label>
              <v-text-field
                v-model="holder.cpf"
                class="mt-2"
                placeholder="Informe o número"
                v-mask="'###.###.###-##'"
                outlined
                color="textPrimary"
                :disabled="!isFreeMovement"
                :rules="holder.cpf ? [rule.cpf] : []"
                validate-on-blur
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label class="label">Nome</label>
              <v-text-field
                class="mt-2"
                v-model.trim="holder.name"
                placeholder="Informe o Nome"
                :rules="holder.name ? [rule.validateIsAlphanumeric, rule.name] : []"
                :disabled="!isFreeMovement"
                validate-on-blur
                outlined
                color="textPrimary"
                @blur="holder.name = formatter.formatToTitleCase(holder.name)"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label class="label">Matrícula</label>
              <v-text-field
                class="mt-2"
                v-model="holder.registrationPlate"
                :disabled="!isFreeMovement"
                placeholder="Informe a Matrícula"
                outlined
                color="textPrimary"
              />
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <label class="label">Código familiar</label>
              <v-text-field
                outlined
                class="mt-2"
                v-model="dependent.familyCode"
                v-mask="`${ formatter.maskLimiter(10) }`"
                color="textPrimary"
                placeholder="Informe código familiar"
                :disabled="isDisabledFamilyCode"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-if="this.isFreeMovement" class="mb-8">
      <v-col cols="12">
        <p class="title">Informações do Plano</p>
      </v-col>
      <v-col cols="12">
        <v-form ref="isValidFormHolderPlans" v-model="isValidFormHolderPlans">
          <v-data-table
            :headers="headersHolderPlan"
            :items="holder.plans"
            hide-default-footer
            item-key="id"
          >
            <template v-slot:no-data>
              <span>Nenhum item foi encontrado.</span>
            </template>

            <template v-slot:[`item.financialGroup`]="{ item }">
              <v-autocomplete
                :items="financialGroups"
                :loading="loadingFinancialGroups"
                :disabled="loadingFinancialGroups"
                v-model="item.financialGroupId"
                placeholder="Grupo Financeiro"
                label="Grupo Financeiro"
                item-text="name"
                item-value="id"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :rules="[rule.required]"
                @input="loadContractsByFinancialGroupId(item)"
                validate-on-blur
              />
            </template>

            <template v-slot:[`item.contract`]="{ item }">
              <v-autocomplete
                class="my-4"
                v-model="item.contractId"
                :items="item.contracts"
                :loading="loadingContracts"
                :disabled="loadingContracts"
                item-text="name"
                item-value="id"
                placeholder="Contrato"
                label="Contrato"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :rules="item.contractId !== null ? [] : [rule.required]"
                @input="loadSubcontractsByContractId(item)"
                validate-on-blur
              />
            </template>

            <template v-slot:[`item.subcontract`]="{ item }">
              <v-autocomplete
                class="my-4"
                v-model="item.subContractId"
                :items="item.subcontracts"
                :loading="loadingSubContracts"
                :disabled="loadingSubContracts"
                item-text="name"
                item-value="id"
                placeholder="Subcontrato"
                label="Subcontrato"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :rules="item.subContractId !== null ? [] : [rule.required]"
                @input="loadEligibilitiesByContractIdAndSubcontractId(item)"
                validate-on-blur
              />
            </template>

            <template v-slot:[`item.eligibility`]="{ item }">
              <v-autocomplete
                class="my-4"
                v-model="item.eligibilityId"
                :items="item.eligibilities"
                :loading="loadingEligibilities"
                :disabled="loadingEligibilities"
                item-text="name"
                item-value="id"
                placeholder="Elegibilidade"
                label="Elegibilidade"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :rules="item.eligibilityId !== null ? [] : [rule.required]"
                @input="loadPlansByEligibilityId(item)"
                validate-on-blur
              />
            </template>

            <template v-slot:[`item.plan`]="{ item }">
              <v-autocomplete
                class="my-4"
                v-model="item.planId"
                :items="item.plans"
                :loading="loadingPlans"
                :disabled="loadingPlans"
                item-text="name"
                item-value="id"
                placeholder="Plano"
                label="Plano"
                color="textPrimary"
                item-color="textPrimary"
                append-icon="fas fa-chevron-down"
                :rules="item.planId !== null ? [] : [rule.required]"
                @input="loadPlan(item)"
                validate-on-blur
              />
            </template>

            <template v-slot:[`item.cardNumber`]="{ item }">
              <div class="d-flex flex-column">
                <v-text-field
                  label="Número do Cartão"
                  v-mask="'###############'"
                  v-model="item.cardNumber"
                  placeholder="Número do Cartão"
                  color="textPrimary"
                  :rules="item.cardNumber !== null ? [] : [rule.required]"
                  validate-on-blur
                  @input="checkCardNumber(item)"
                />
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                class="mt-3"
                color="textPrimary"
                dark
                icon
                @click="onClickAddPlan()"
              >
                <v-icon size="20" color="textPrimary">fas fa-plus</v-icon>
              </v-btn>
              <v-btn
                :disabled="holder && holder.plans && holder.plans.length === 1"
                color="textPrimary"
                dark
                icon
                class="mt-3"
                @click="onClickDelPlan(item)"
              >
                <v-icon color="textPrimary" size="20"
                  >fas fa-minus</v-icon
                >
              </v-btn>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="px-6" elevation="1">
          <v-row>
            <v-col cols="12">
              <p class="title">Adicionar Dependente</p>
            </v-col>
          </v-row>
          <v-form ref="isValidFormDependentInfo" v-model="isValidFormDependentInfo">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  label="CPF"
                  placeholder="Informe o CPF"
                  v-model="dependent.document"
                  v-mask="'###.###.###-##'"
                  :rules="dependent.document ? [rule.cpf] : []"
                  outlined
                  color="textPrimary"
                  :disabled="loadingSearchDependent"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.fullName"
                  label="Nome Completo"
                  maxlength="70"
                  placeholder="Informe o nome completo"
                  :rules="dependent.fullName ? [rule.validateIsAlphanumeric, rule.name] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                  :disabled="loadingSearchDependent"
                  @blur="dependent.fullName = formatter.formatToTitleCase(dependent.fullName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data de Nascimento"
                    v-model="dependent.birthDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :disabled="loadingSearchDependent"
                    :rules="dependent.birthDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                    @input="cleanAllowedDates()"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  v-model="dependent.stateBirth"
                  :items="states"
                  item-text="label"
                  item-value="label"
                  outlined
                  label="UF de Nascimento"
                  color="textPrimary"
                  item-color="textPrimary"
                  :disabled="loadingSearchDependent"
                  append-icon="fas fa-chevron-down"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="genders"
                  v-model="dependent.gender"
                  item-text="description"
                  item-value="id"
                  clearable
                  label="Gênero"
                  :disabled="loadingSearchDependent"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="4">
                <v-text-field
                  v-model.trim="dependent.motherName"
                  label="Nome da Mãe"
                  maxlength="70"
                  placeholder="Informe o nome da mãe"
                  :disabled="loadingSearchDependent"
                  :rules="dependent.motherName ? [rule.name, rule.validateIsAlphanumeric] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                  @blur="dependent.motherName = formatter.formatToTitleCase(dependent.motherName)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-autocomplete
                  :items="kinships"
                  v-model="dependent.relationship"
                  item-text="description"
                  item-value="id"
                  :disabled="loadingSearchDependent"
                  clearable
                  label="Grau de Parentesco"
                  :rules="[rule.required]"
                  outlined
                  color="textPrimary"
                  @input="loadPlans()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="dependent.cns"
                  label="CNS"
                  placeholder="Informe o CNS"
                  :disabled="loadingSearchDependent"
                  v-mask="'###############'"
                  :rules="dependent.cns ? [rule.cns] : []"
                  validate-on-blur
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  ref="dnv"
                  v-model="dependent.dnv"
                  v-mask="'##-########-#'"
                  label="Declaração de Nascido Vivo (DNV)"
                  :disabled="loadingSearchDependent"
                  placeholder="Informe o DNV"
                  outlined
                  :rules="dependent.dnv ? [rule.dnv] : []"
                  validate-on-blur
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
               <v-text-field
                  label="Peso(kg)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  :disabled="loadingSearchDependent"
                  placeholder="Informe o peso"
                  v-model="dependent.weight"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.weight = formatter.removeInvalidNumber(dependent.weight)"
                  @blur="validateNumbersGreaterThanOne(dependent.weight, 'fieldCheckedWeight')"
                  id="fieldCheckedWeight"
                  outlined
                  color="textPrimary"
                  :rules="controlWeight ? ['Favor informar um número válido'] : dependent.weight ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.weight)] : []"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                <v-text-field
                  label="Altura(cm)"
                  v-mask="'###'"
                  type="number"
                  min="1"
                  placeholder="Informe a altura"
                  :disabled="loadingSearchDependent"
                  v-model="dependent.height"
                  @keydown="$event.key === '-' || $event.key === '.' || $event.key === ',' ? $event.preventDefault() : null"
                  @input="dependent.height = formatter.removeInvalidNumber(dependent.height);"
                  @blur="validateNumbersGreaterThanOne(dependent.height, 'fieldCheckedHeight')"
                  id="fieldCheckedHeight"
                  outlined
                  color="textPrimary"
                  :rules="controlHeight ? ['Favor informar um número válido'] : dependent.height ? [rule.validatesNumberGreaterThanOrEqualToZero(dependent.height)] : []"
                />
              </v-col>
              <v-col v-if="conditionShowEventDate()" cols="12" sm="6" md="4" lg="3" xl="2">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    label="Data do evento"
                    v-model="dependent.eventDate"
                    :disabled="loadingSearchDependent"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="dependent.eventDate ? [rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                    validate-on-blur
                    @input="cleanAllowedDates()"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  :items="maritalStatus"
                  v-model="dependent.maritalStatus"
                  label="Estado Civil"
                  item-text="description"
                  :disabled="loadingSearchDependent"
                  item-value="id"
                  outlined
                  color="textPrimary"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  label="Complemento de Matrícula"
                  placeholder="Informe o complemento"
                  v-model="dependent.registrationComplement"
                  maxlength="50"
                  outlined
                  color="textPrimary"
                />
              </v-col>
              <v-col cols="12">
                <label class="title">Dados de Contato</label>
                <v-row class="my-5">
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Telefone Residencial"
                      placeholder="Informe o número"
                      v-model="dependent.telephone"
                      lazy-validation
                      v-mask="'(##) ####-####'"
                      :rules="dependent.telephone ? [rule.telephone] : []"
                      validate-on-blur
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="Celular"
                      placeholder="Informe o número"
                      v-model="dependent.cellphone"
                      v-mask="'(##) #####-####'"
                      :rules="dependent.cellphone ? [rule.cellphone] : []"
                      validate-on-blur
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      label="E-Mail"
                      placeholder="exemplo@email.com.br"
                      :rules="dependent.email ? [rule.email] : []"
                      v-model="dependent.email"
                      outlined
                      color="textPrimary"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <label class="title">Identidade</label>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">Natureza</label>
                    <v-autocomplete
                      class="mt-2"
                      v-model="dependent.identityDocumentNature"
                      :items="identityDocumentNatureTypes"
                      @input="dependent.identityDocumentNumber = null; "
                      placeholder="Informe a natureza do documento"
                      color="textPrimary"
                      outlined
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Documento</label>
                    <v-text-field
                      v-model="dependent.identityDocumentNumber"
                      :rules="dependent.identityDocumentNumber ? [rule.rgMaxDigit(dependent.identityDocumentNature, dependent.identityDocumentNumber)] : []"
                      validate-on-blur
                      v-mask="'NNNNNNNNNNNNNNN'"
                      class="mt-2"
                      placeholder="Informe o número do documento"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Data de Expedição</label>
                    <div class="d-flex flex-column">
                      <v-text-field
                        outlined
                        v-model="dependent.identityDocumentEmissionDate"
                        append-icon="fas fa-calendar-alt"
                        placeholder="DD/MM/YYYY"
                        v-mask="'##/##/####'"
                        color="textPrimary"
                        class="mt-2"
                        :rules="dependent.identityDocumentEmissionDate ? [rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid, rule.isBeforeDateNow] : []"
                        validate-on-blur
                      />
                    </div>
                  </v-col>
                  <v-col cols="3">
                    <label class="label">Orgão Emissor</label>
                    <v-text-field
                      maxlength="8"
                      v-mask="'AAAAAAAA'"
                      @input="dependent.identityDocumentIssuer = dependent.identityDocumentIssuer.toUpperCase();"
                      v-model="dependent.identityDocumentIssuer"
                      class="mt-2"
                      placeholder="Informe o órgão emissor"
                      outlined
                      color="textPrimary"
                    />
                  </v-col>
                </v-row>
                <v-row class="my-5">
                  <v-col cols="3">
                    <label class="label">UF Emissor</label>
                    <v-autocomplete
                      v-model="dependent.identityDocumentState"
                      :items="states"
                      class="mt-2"
                      item-text="label"
                      item-value="label"
                      outlined
                      placeholder="UF emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <label class="label">País Emissor</label>
                    <v-autocomplete
                      v-model="dependent.identityDocumentCountry"
                      :items="country"
                      class="mt-2"
                      item-text="text"
                      item-value="value"
                      outlined
                      placeholder="País emissor"
                      color="textPrimary"
                      item-color="textPrimary"
                      append-icon="fas fa-chevron-down"
                      clearable
                    />
                  </v-col>
                </v-row>
              </v-col>
              <div ref="addressInformationComponent">
                <AddressInformations
                  class="pt-0"
                  :addressData="addressData"
                  :showHeaderPage="true"
                  :isFreeMovement="false"
                  :isDetail="true"
                  @observerAddressData="updateAddressData"
                />
              </div>
            </v-row>
          </v-form>

          <v-row class="d-flex justify-end" v-if="!this.isFreeMovement">
            <v-col cols="12"class="d-flex flex-row justify-end">
              <v-btn
                :disabled="!this.holderAddress"
                class="white--text"
                x-large
                color="textPrimary"
                @click="getHolderAddress()"
              >
                Carregar Endereço do Titular
              </v-btn>
              <v-btn
                :disabled="loadingSearchDependent || !((dependent.document && rule.cpf(dependent.document) === true) || (dependent.birthDate && rule.isDateValid(dependent.birthDate) === true) || (dependent.fullName && rule.name(dependent.fullName) === true))"
                :loading="loadingSearchDependent"
                class="white--text ml-2"
                x-large
                color="textPrimary"
                @click="loadPlans()"
              >
                Carregar Planos
              </v-btn>
            </v-col>
          </v-row>

          <v-form ref="isValidFormDependentPlans" v-model="isValidFormDependentPlans">
            <v-row class="mb-8">
              <v-col>
                <v-row class="mt-5 pr-4">
                  <v-col cols="12" md="6">
                    <p class="title">Dados do Plano</p>
                  </v-col>
                </v-row>
                <v-data-table
                  class="elevation-1 mb-5"
                  :calculate-widths="true"
                  :headers="headersDependentPlan"
                  :items="holder.plans"
                  :loading="loadingDependentPlans"
                  hide-default-footer
                  item-key="id"
                >
                  <template v-slot:no-data>
                    <span>Nenhum item foi encontrado.</span>
                  </template>
                  <template v-slot:[`header.multiselect`]="{}">
                    <v-row class="justify-center">
                      <v-checkbox
                        color="textPrimary"
                        v-model="isSelectedAllPlans"
                        :disabled="loadingDependentPlans"
                        @click="selectAllPlans()"
                      />
                    </v-row>
                  </template>
                  <template v-slot:[`item.multiselect`]="{ item }">
                    <v-row class="justify-center align-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <v-checkbox
                              class="mt-5"
                              color="textPrimary"
                              :value="item.selected"
                              v-model="item.selected"
                              @change="loadDocuments(item)"
                              @click="verifyPlanDocuments(item)"
                              :disabled="loadingDependentPlans || item.registered || (isFreeMovement && (!item.planId || !item.cardNumber))"
                              :readonly="loadingDependentPlans || item.registered || (isFreeMovement && (!item.planId || !item.cardNumber))"
                            />
                          </div>
                        </template>
                        <span>Selecionar</span>
                      </v-tooltip>
                    </v-row>
                  </template>
                  <template v-slot:[`item.validityDate`]="{ item, index }">
                    <v-menu
                      v-model="validityDateMenu[index]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto">
                      <template
                        v-slot:activator="{ on, attrs }">
                        <v-text-field
                          label="Data de Vigência (DD/MM/YYYY)"
                          v-model="item.validityDate"
                          placeholder="Informe a Data de Vigência do Plano"
                          v-mask="'##/##/####'"
                          color="textPrimary"
                          @click="getAllowedDates(item, index)"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          clearable
                          :disabled="loadingDependentPlans || item.registered || (isFreeMovement && (!item.planId || !item.cardNumber))"
                          :rules="item.validityDate || !item.selected || item.registered ? [] : [rule.isAfterSpecificYear, rule.isDateValid, rule.required]"
                        />
                      </template>
                      <AllowedDatePicker
                        @date="
                          !cleaningAllowedDates
                          ? updateValidityDate($event, item, index)
                          : false"
                        :allowedDates="allowedDates"
                      />
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="isValidFormDependentDocs" v-model="isValidFormDependentDocs">
            <v-row v-if="documentsList.length > 0" class="pr-4">
              <v-col cols="12">
                <v-row class="my-3 ml-1">
                  <v-col cols="12" md="6" class="py-0">
                    <p
                      v-if="hasAttachment"
                      class="title pb-0 mb-0">
                      Upload de Documentos
                    </p>
                  </v-col>

                  <v-col
                    v-for="(document, documentIndex) in documentsList" :key="documentIndex"
                    cols="12"
                    class="px-0"
                    v-show="documentsVisibility[`UploadDocuments-#C${document.contractId}-#I${document.carrierId}`]">
                    <p
                      class="text-h6 wineLabel--text ml-5">
                      {{ document.contractName }}
                    </p>
                    <v-col cols="12 pt-0">
                      <UploadDocuments
                        :ref="`UploadDocuments-#C${document.contractId}-#I${document.carrierId}`"
                        class="pt-0 mx-3 ml-6"
                        :showEditAndSaveButton="false"
                        :showDownloadButton="false"
                        :updateParent="true"
                        :useProps="true"
                        :documentParams="document.params"
                        @observerDocumentData="observerDocumentData($event, document)"
                        :startSearch="startSearch"
                        @searchEnded="startSearch = false"
                        @cleanThisDocuments="cleanThisDocuments(document)"
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>

          <div v-if="violations.length > 0" class="mt-10">
            <ViolationRulesAlert :violations="violations" />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-footer>
      <v-row class="d-flex justify-end">
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            :disabled="overlay"
            x-large
            block
            text
            color="textPrimary"
            @click="onClickBack()"
          >
            Voltar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            :disabled="overlay"
            x-large
            block
            outlined
            color="textPrimary"
            @click="redirectToHome()"
          >
            Cancelar
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="2" lg="2">
          <v-btn
            :disabled="overlay"
            :loading="overlay"
            class="white--text"
            x-large
            block
            color="textPrimary"
            @click="onClickAddDependent()"
          >
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog :value="showAlertSelectPlan" persistent max-width="1080">
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12">
              <v-icon
                large
                color="textPrimary"
                @click="showAlertSelectPlan = false">fas fa-times
              </v-icon>
            </v-col>
            <v-col
              class="text-h4"
              cols="12"
              style="color: #3b495b; font-weight: bold">
              Você não incluiu plano para o dependente <br> ou dependente já encontra-se no plano selecionado!
            </v-col>
            <v-col class="text-h5" cols="12" style="color: #3b495b">
              Inclua algum plano para o dependente para finalizar.
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions class="mt-10">
          <v-row class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              style="color: white"
              @click="showAlertSelectPlan = false">
              OK
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="showAlertDependentHolder" persistent max-width="1080">
      <v-card class="pa-5">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12">
              <v-icon
                large
                color="textPrimary"
                @click="showAlertDependentHolder = false">fas fa-times
              </v-icon>
            </v-col>
            <v-col
              class="text-h4"
              cols="12"
              style="color: #3b495b; font-weight: bold">
              O CPF informado pertence a um titular!
            </v-col>
            <v-col class="text-h5" cols="12" style="color: #3b495b">
              Tem certeza que deseja cadastra-lo como um dependente?
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-row class="mt-6 justify-center">
            <v-btn
              class="mr-3 pa-7 btn-dialog"
              color="#3B495B"
              outlined
              @click="showAlertDependentHolder = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="mr-6 pa-7 btn-dialog"
              color="#3B495B"
              style="color: white"
              @click="onClickconfirmAddDependent()"
            >
              Confirmar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ReleaseMovementModal
      ref="ReleaseMovementModal"
      @close="closeAllDialogInsertListMovements()"
      @confirm="updateStatus()"
    />

    <TransitionMovementModal
      ref="TransitionMovementModal"
      @makeNewMovement="backToInclusionSearchHolderPage"
      @goToMovementList="goToMovementPage"
      :orderGoToMovementList="3">
      <template slot="buttons">
        <v-col
          cols="6"
          order="2"
          class="d-flex justify-center">
          <v-btn large outlined color="wineLabel" @click="addNewDependent">
            <v-icon left>mdi mdi-plus</v-icon>
            <span>ADICIONAR NOVO DEPENDENTE</span>
          </v-btn>
        </v-col>
      </template>
    </TransitionMovementModal>

    <InfoMovementDialog
      :show="showReleaseInfo"
      :movementType="movementType"
      :messageType="releaseMessageType"
      :beneficiaryName="dependent && dependent.fullName ? formatter.formatToTitleCase(dependent.fullName) :null"
      @close="closeDialogInsertListMovements()"
    />

    <v-snackbar
      v-model="snackbar.show"
      timeout="10000"
      top
      elevation="24"
      :color="snackbar.type"
    >
      <div class="text-center">{{ snackbar.text }}</div>
      <template v-slot:action="{ attrs }">
        <v-icon
          text
          dark
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          close
        </v-icon>
      </template>
    </v-snackbar>

    <PhysicalPersonDialog
      :show="showDialogPhysicalPersons"
      :physicalPersons="physicalPersons"
      @confirm="confirmPhysicalPersonDialog"
      @close="showDialogPhysicalPersons = false"
    />

    <v-overlay v-model="overlay">
      <v-progress-circular
        indeterminate
        size="120"
      >
        <div>
          Carregando...
        </div>
      </v-progress-circular>
    </v-overlay>

    <CriticismHandlingModal ref="CriticismHandlingModal" @confirm="onClickAddDependent" />
    <v-dialog v-model="alertGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeAlertGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>Os campos Data de Vigência, Data do Evento e Data de Nascimento são obrigatórios para as operadoras: {{[this.invalidGraceContractNames.slice(0, -1).join(', '), this.invalidGraceContractNames.slice(-1)[0]].join(this.invalidGraceContractNames.length < 2 ? '' : ' e ')}}</p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeAlertGraceModal()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="validateGraceModal" persistent max-width="800">
      <v-card width="800" class="pa-3 rounded-lg">
        <v-card-title class="text-h5 font-weight-bold" style="color: #3b495b;">
          <v-row
            class="d-flex justify-space-between px-3 pt-3">
            <span>Incidência de Carência</span>
            <v-icon @click="closeValidateGraceModal()">
              fas fa-times
            </v-icon>
          </v-row>
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="formFilterExportMovement">
            <v-row justify="start">
              <v-col>
                <p>A movimentação desejada excede o prazo máximo dias com relação à Data do Evento. Portanto, incidirá carência.</p>
                <div v-for="grace in gracePeriods">
                  <p v-if="grace.hasGracePeriod">
                    {{grace.benefitId}} - {{grace.policy}} - Acima do prazo máximo de {{grace.daysGracePeriod}} dias
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions
          class="px-6 pb-3">
          <v-spacer />
          <v-btn color="primary" width="150px" outlined @click="closeValidateGraceModal()">
            Cancelar
          </v-btn>
          <v-btn color="primary" width="150px" outlined @click="nonConfirmGracePeriod()">
            Atendimento
          </v-btn>
          <v-btn color="primary" width="150px" @click="confirmGracePeriod()">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackbarCustomize ref="SnackbarCustomize" />
  </v-container>
</template>

<script>
import lodash from 'lodash';
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import MaritalStatusService from '@/services-http/contract/MaritalStatusService';
import GenderService from '@/services-http/contract/GenderService';
import BeneficiaryContractedPlanService from '@/services-http/contract/BeneficiaryContractedPlanService';
import PhysicalPersonService from '@/services-http/contract/PhysicalPersonService';
import KinshipService from '@/services-http/contract/KinshipService';
import MovementRecordService from '@/services-http/sdi/MovementRecordService';
import PhysicalPersonDialog from '@/components/PhysicalPersonDialog/PhysicalPersonDialog.vue';
import DocumentTypeService from '@/services-http/sdi/DocumentTypeService';
import DocumentService from '@/services-http/sdi/DocumentService';
import BeneficiaryService from '@/services-http/contract/BeneficiaryService';
import DependentService from '@/services-http/contract/DependentService';
import ConstantsMixin from '@/shared/mixins/constants/constantsMixin';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupService from '@/services-http/contract/FinancialGroupService';
import ContractService from '@/services-http/contract/ContractService';
import ContractEligibilityService from '@/services-http/contract/ContractEligibilityService';
import ContractProductService from '@/services-http/contract/ContractProductService';
import UserUtils from '@/shared/utils/user-utils';
import InfoMovementDialog from '@/components/MovementDialog/InfoMovementDialog.vue';
import ViolationRulesAlert from '@/components/CustomAlerts/ViolationRulesAlert.vue';
import PayrollService from '@/services-http/sdi/PayrollService';
import ReleaseMovementModal from '@/components/ReleaseMovement/ReleaseMovementModal.vue';
import { store } from '@/shared/observable/store';
import CriticismHandlingModal from '@/components/CustomAlerts/CriticismHandlingModal.vue';
import AllowedDatePicker from '@/components/AllowedDatePicker/AllowedDatePicker.vue';
import CarrierService from '@/services-http/contract/CarrierService';
import moment from 'moment';
import plansMixin from '@/shared/mixins/sdi/plansMixin';
import TransitionMovementModal from '@/components/TransitionMovementModal/TransitionMovementModal.vue';
import AddressInformations from '@/components/Beneficiary/Holder/AddressInformations.vue';
import MovementDocumentService from '@/services-http/sdi/MovementDocumentService';
import { mapGetters, mapMutations } from 'vuex';
import UploadDocuments from '@/components/Beneficiary/Documents/UploadDocuments.vue';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default {
  name: 'DependentInclusionSave',
  components: {
    ViolationRulesAlert,
    InfoMovementDialog,
    PhysicalPersonDialog,
    ReleaseMovementModal,
    CriticismHandlingModal,
    AllowedDatePicker,
    TransitionMovementModal,
    AddressInformations,
    UploadDocuments,
    SnackbarCustomize,
  },
  data: () => ({
    moment: moment,

    beneficiary: null,

    maritalStatus: [],
    genders: [],
    kinships: [],
    financialGroups: [],
    physicalPersons: [],
    documentsList: [],
    violations: [],
    waitingRulesApprovalIds: [],
    gracePeriods: [],
    invalidGraceContractNames: [],
    carriers: [],
    documents: [],
    documentParams: {
      contractId: null,
      carrierId: null,
      movementType: 'INSERT',
      hasGracePeriod: false,
    },
    startSearch: false,
    query: '',
    movementType: 'Inclusão Dependente',
    successReleaseMessageType: 'RELEASE_SUCCESS',
    errorReleaseMessageType: 'RELEASE_ERROR',
    releaseMessageType: '',

    controlHeight: false,
    controlWeight: false,
    hasAuthorityReleaseMovement: false,
    isValidFormHolderInfo: false,
    isValidFormHolderPlans: false,
    isValidFormDependentInfo: false,
    isValidFormDependentDocs: false,
    isValidFormDependentPlans: false,
    isDisabledFamilyCode: false,
    isContinue: false,

    showReleaseInfo: false,
    showDialogPhysicalPersons: false,
    showAlertDependentHolder: false,
    showDialogInsert: false,
    showAlertSelectPlan: false,
    isSelectedAllPlans: false,
    isFreeMovement: false,
    isBeneficiary: false,
    isRHProtegido: false,
    skipDuplicityCheck: false,
    validateGraceModal: false,
    alertGraceModal: false,
    hasAcceptedGracePeriod: null,
    validityDateMenu: [],

    overlay: true,
    loadingSearchDependent: false,
    waitingDocumentUpload: false,
    loadingFinancialGroups: false,
    loadingContracts: false,
    loadingSubContracts: false,
    loadingEligibilities: false,
    loadingPlans: false,
    loadingDependentPlans: false,
    carrierService: null,
    allowedDates: [],
    cleaningAllowedDates: false,
    addressData: {},
    address: {},
    holderAddress: {},
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
    holder: {
      cpf: null,
      name: null,
      registrationPlate: null,
      beneficiaryId: null,
      financialGroupId: null,
      beneficiaryContractedPlanId: null,
      plans: [],
      address: {},
    },
    dependent: {
      document: null,
      fullName: null,
      birthDate: null,
      maritalStatus: null,
      gender: null,
      relationship: null,
      cns: null,
      dnv: null,
      motherName: null,
      height: null,
      weight: null,
      documents: [],
      eventDate: null,
      validityDate: null,
      stateBirth: null,
      plans: [],
      holder: {
        name: '',
        cpf: '',
        registrationPlate: '',
      },
      familyCode: null,
      registrationComplement: null,
      email: null,
      telephone: null,
      identityDocumentNature: null,
      identityDocumentNumber: null,
      identityDocumentEmissionDate: null,
      identityDocumentIssuer: null,
      identityDocumentState: null,
      identityDocumentCountry: null,
    },
    movementDocuments: [],
    movementDocumentService: null,

    headersHolderPlan: [
      {
        text: 'Grupo Financeiro',
        value: 'financialGroup',
        align: 'center',
      },
      {
        text: 'Apólice / Contrato',
        value: 'contract',
        align: 'center',
      },
      {
        text: 'Subcontrato',
        value: 'subcontract',
        align: 'center',
      },
      {
        text: 'Elegibilidade',
        value: 'eligibility',
        align: 'center',
      },
      {
        text: 'Plano',
        value: 'plan',
        align: 'center',
      },
      {
        text: 'Número do Cartão',
        value: 'cardNumber',
        align: 'center',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    headersDependentPlan: [
      {
        text: '',
        value: 'multiselect',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Apólice / Contrato',
        value: 'contractName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Sub-Contrato',
        value: 'subcontractName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Plano',
        value: 'planName',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
      {
        text: 'Início de Vigência',
        value: 'validityDate',
        align: 'center',
        class: 'text--truncate',
        sortable: false,
      },
    ],
    persistWithCriticism: false,
    documentsVisibility: {},
  }),

  async mounted() {
    if (this.$route.query && this.$route.query.isRHProtegido === 'true') this.isRHProtegido = true;
    await this.loadAuthorityMovement();
    await this.loadGenders();
    await this.loadMaritalStatus();
    await this.loadKiship();
    if (this.$route.query.freeMovement && (this.$route.query.freeMovement === 'true' || this.$route.query.freeMovement === true)) {
      this.isFreeMovement = true;
    }
    await this.loadSessionStorage();

    if (this.isFreeMovement) {
      this.onClickAddPlan();

      await this.loadFinancialGroups();
    } else {
      await this.loadHolderBeneficiaryId();
    }

    this.overlay = false;
  },

  mixins: [
    ConstantsMixin,
    VerifyRoutesMixin,
    AuthorityManagementMixin,
  ],

  watch: {
    'dependent.document': function searchDependentDocument() {
      if (!this.isFreeMovement) {
        this.holder.plans = [];
        this.documentsList = [];
        if (this.dependent.document && this.rule.cpf(this.dependent.document) === true) {
          this.getBeneficiaries();
        }
      }
    },
    'dependent.fullName': function () {
      if (!this.isFreeMovement) {
        this.holder.plans = [];
      }
    },
    'dependent.relationship' : function () {
      if (!this.isFreeMovement) {
        if(this.holder.plans && (this.holder.plans.length > 0)) {
          this.loadPlans();
        }
      }
    },
    getObservableStore(val) {
      if (val && !val.show) {
        if (val.viewMovements) {
          sessionStorage.setItem('movementRecordIds', JSON.stringify(val.data.movementRecordIds));
          this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
        }

        if (val.skipDuplicityCheck) {
          this.skipDuplicityCheck = true;
          this.onClickAddDependent();
        }
      }
    },
    documentsList: {
      handler(documents) {
        if (!documents || documents && documents.length === 0) {
            this.isSelectedAllPlans = false;
        }
      }
    },
    startSearch: {
      handler(searchFinished) {
        if (searchFinished === false) {
          setTimeout(() => {
            this.checkAllUploadDocuments();
          }, 500)
        }
      },
    }
  },

  computed: {
    ...mapGetters({
      searchParams: 'handler/searchParams',
    }),
    getObservableStore() {
      return store.dialogInformation;
    },
    hasAttachment() {
      return this.documentsList.length
        && this.documentsList.length > 0
        && this.documentsList.some(
          (document) => this.documentsVisibility[`UploadDocuments-#C${document.contractId}-#I${document.carrierId}`] === true);
    },
  },

  methods: {
    ...mapMutations({
      setSearchParams: 'handler/setSearchParams',
    }),
    checkAllUploadDocuments() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('UploadDocuments-')) {
          const docComponentReference = this.$refs[ref];

          const hasDocuments = docComponentReference
            && docComponentReference.length > 0
            && docComponentReference[0].documentsList
            && docComponentReference[0].documentsList.length > 0;

          this.$set(this.documentsVisibility, ref, hasDocuments);
        }
      });

      this.startSearch = false;
    },
    resetAllDocuments() {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('UploadDocuments-')) {
          const docComponentReference = this.$refs[ref];

          if (docComponentReference && docComponentReference.length > 0) {
            docComponentReference[0].resetDocuments();
          }
        }
      });
    },
    fileRulesValidation(item) {
      if (item.configuration && Object.keys(item.configuration).length > 0) {
        if (item.configuration.mandatory)
          return [ this.rule.required ];

        if (this.documentParams.hasGracePeriod && item.configuration.gracePeriod)
          return [ this.rule.required ];
      }

      return [];
    },
    fileUploadRulesValidation(item) {
      if (item.file) return [ this.rule.validateUploadFiles(item) ];

      return this.fileRulesValidation(item);
    },
    setSessionStoreData() {
      if (sessionStorage.getItem('searchParams')) {
        this.setLayout(JSON.parse(sessionStorage.getItem('searchParams')));
        sessionStorage.removeItem('searchParams')
      }
    },
    setAddressData(data) {
      if (data.activeAddress !== null) {
        this.addressData = {
          city: data.activeAddress.addressCity ? data.activeAddress.addressCity : null,
          log: data.activeAddress.address ? data.activeAddress.address : null,
          code: data.activeAddress.addressZip ? data.activeAddress.addressZip.replace('-', '') : null,
          country: data.activeAddress.addressCountry ? data.activeAddress.addressCountry : null,
          neighborhood: data.activeAddress.addressNeighborhood ? data.activeAddress.addressNeighborhood : null,
          number: data.activeAddress.addressNumber ? data.activeAddress.addressNumber : null,
          region: data.activeAddress.addressState ? data.activeAddress.addressState : null,
          complement: data.activeAddress.addressComplement ? data.activeAddress.addressComplement : null,
          logType: data.activeAddress.addressType ? data.activeAddress.addressType.toUpperCase() : null,
          ibgeCode: data.activeAddress.ibgeCode ? data.activeAddress.ibgeCode : null,
        };
      }
    },
    updateAddressData(data) {
      this.address.code = data.code;
      this.address.log = data.log;
      this.address.city = data.city;
      this.address.region = data.region;
      this.address.logType = data.logType;
      this.address.country = data.country;
      this.address.neighborhood = data.neighborhood;
      this.address.number = data.number;
      this.address.complement = data.complement;
      this.address.ibgeCode = data.ibgeCode;
    },
    getHolderAddress() {
      this.addressData = this.holderAddress;
    },
    getHolderAddress() {
      this.addressData = this.holderAddress;
    },
    verifyPlanDocuments(item) {
      if (item.selected !== true) {
        item.validityDate = null;

        const documentIdsToRemove = this.dependent.documents.filter(el => el.carrierId === item.carrierId).map(obj => obj.id);

        for (let i = this.dependent.documents.length - 1; i >= 0; i--) {
          if (documentIdsToRemove.includes(this.dependent.documents[i].id)) {
            this.dependent.documents.splice(i, 1);
          }
        }
      }
    },
    async getAllowedDates(item) {
      let dateEvent = this.getValidEventDate(item)

      if (dateEvent !== null & isNaN(Date.parse(dateEvent))) {
        this.customizeSnackbarMessage('error', 'É necessário informar a ' + this.getStringValidationDate(item));
        this.allowedDates = [null];
        return null;
      }

      await this.carrierService.GetAllowedDates([item.contractId], 'INSERT_DEPENDENT', dateEvent).then(response => {
        if (response.status === 200) {
          this.allowedDates = response.data;
        }
      })
    },
    cleanAllowedDates() {
      this.allowedDates = [null];
      this.cleaningAllowedDates = true;

      setTimeout(() => {
        this.holder.plans.forEach(plan => {
          plan.validityDate = null;
        });
      }, 500)

      this.cleaningAllowedDates = false;
    },
    getValidEventDate(item) {
      let evKinships = [5,15,17,18,19,24,22];
      let bdKinships = [3,16,25];
      if(evKinships.includes(this.dependent.relationship)) {
        return moment(this.dependent.eventDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      } else if(bdKinships.includes(this.dependent.relationship)){
        return moment(this.dependent.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      return null;
    },
    getStringValidationDate(item) {
      let evKinships = [5,15,17,18,19,24,22];
      let bdKinships = [3,16,25];
      if(evKinships.includes(this.dependent.relationship)) {
        return 'Data do Evento';
      } else if(bdKinships.includes(this.dependent.relationship)){
        return 'Data de Nascimento';
      }
      return null;
    },
    updateValidityDate(value, item, index) {
      if (Boolean(value)) {
        item.validityDate = moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
        this.validityDateMenu.splice(index, 1);
      }
    },
    async loadFinancialGroups() {
      this.loadingFinancialGroups = true;

      const queryString = '?orderByName=true';
      await this.financialGroupService.FindAllWithFilters(queryString).then(async (response) => {
        if (response && response.data) {
          this.financialGroups = response.data;
        }
      }).catch(() => {
        this.loadingFinancialGroups = false;
      }).finally(() => {
        this.loadingFinancialGroups = false;
      });
    },
    async loadContractsByFinancialGroupId(item) {
      // eslint-disable-next-line no-param-reassign
      item.financialGroupId = Number(item.financialGroupId);

      this.loadingContracts = true;

      // eslint-disable-next-line no-param-reassign
      item.subcontracts = [];
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      let query = `financialGroupIds=${item.financialGroupId}`;

      if (this.isRHProtegido) {
        query += `&allowMovementByProtectedHR=${this.isRHProtegido}`;
      }

      await this.contractService.FindAllByFilters(query).then((response) => {
        if (response && response.data) {
          const contracts = response.data.filter((c) => c.subcontract === false);

          // eslint-disable-next-line no-param-reassign, no-shadow
          item.contracts = contracts.map((item) => {
            const id = Number(item.id);
            const name = `${item.benefit_id && item.benefit_id.name ? item.benefit_id.name : item.benefit_id ? item.benefit_id : ''} - ${item.name || ''}`;

            return {
              id,
              name,
            };
          });
        }

        this.loadingContracts = false;
      }).catch(() => {
        this.loadingContracts = false;
      });
    },
    async loadSubcontractsByContractId(item) {
      // eslint-disable-next-line no-shadow
      const contract = item.contracts.filter((contract) => (contract.id === item.contractId))[0];
      // eslint-disable-next-line no-param-reassign
      item.contractName = contract.name;

      this.loadingSubContracts = true;
      // eslint-disable-next-line no-param-reassign
      item.eligibilities = [];
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      await this.contractService.FindByParentId(item.contractId).then((response) => {
        if (response && response.data) {
          const subContracts = response.data.filter((c) => c.subcontract === true);
          // eslint-disable-next-line no-param-reassign, no-shadow
          item.subcontracts = subContracts.map((item) => {
            const id = Number(item.id);
            const name = `${item.subcontract_number || ''} - ${item.contract_owner_id.legal_name || ''}`;

            return {
              id,
              name,
            };
          });
          item.subcontracts.sort((a, b) => a.name.localeCompare(b.name, { numeric: true }));
        }
        this.loadingSubContracts = false;
      }).catch(() => {
        this.loadingSubContracts = false;
      });
    },
    async loadEligibilitiesByContractIdAndSubcontractId(item) {
      const subContract = item.subcontracts.filter((subcontract) => (subcontract.id === item.subContractId))[0];
      // eslint-disable-next-line no-param-reassign
      item.subcontractName = subContract.name;

      this.loadingEligibilities = true;
      // eslint-disable-next-line no-param-reassign
      item.plans = [];

      const query = `${item.contractId}?subcontractId=${item.subContractId}`;
      await this.contractEligibilityService.FindAllByFilters(query).then((response) => {
        if (response && response.data && response.data) {
          const contractEligibilitiesMap = response.data.map((e) => ({
            key: e.elegibility_id.id,
            value: e.id,
          }));

          const uniqueRecords = response.data.reduce((acc, obj) => {
            const duplicatedIndex = acc.findIndex((it) => it.elegibility_id.id === obj.elegibility_id.id);
            if (duplicatedIndex === -1) {
              acc.push(obj);
            }
            return acc;
          }, []);

          uniqueRecords.forEach((record) => {
            const contractEligibilities = contractEligibilitiesMap.filter(({ key }) => key === record.elegibility_id.id).map(({ value }) => value);
            this.$set(record.elegibility_id, 'contractEligibilities', contractEligibilities);
          });

          // eslint-disable-next-line no-param-reassign
          item.eligibilities = uniqueRecords;

          item.eligibilities.forEach((eligibility) => {
            const found = eligibility.elegibility_id.contractEligibilities.includes(item.eligibilityId);
            if (found) {
              this.$set(eligibility, 'id', item.eligibilityId);
            }
          });
        }

        this.loadingEligibilities = false;
      }).catch(() => {
        this.loadingEligibilities = false;
      });
    },
    async loadPlansByEligibilityId(item) {
      // eslint-disable-next-line no-param-reassign
      item.plans = [];
      if (item.eligibilityId !== null) {
        this.loadingPlans = true;
        const eligibilityFound = item.eligibilities.find((eligibility) => eligibility.id === item.eligibilityId);
        let query = '';
        if (eligibilityFound && eligibilityFound.elegibility_id.contractEligibilities) {
          query = `?contractEligibilitiesIds=${eligibilityFound.elegibility_id.contractEligibilities}`;
        } else if (eligibilityFound && eligibilityFound.id) {
          query = `?contractEligibilitiesIds=${eligibilityFound.id}`;
        }

        await this.contractProductService.FindAllByFilters(query).then((response) => {
          if (response && response.data) {
            const res = response.data;
            const plans = [];
            res.forEach((element) => {
              plans.push({
                eligibility_id: element.eligibility_id,
                ...element.plan_id,
                name: `${element.plan_id.name} - ${element.plan_id.code}`,
              });
              // eslint-disable-next-line no-param-reassign
              item.plans = plans;
            });
          }
          this.loadingPlans = false;
        }).catch(() => {
          this.loadingPlans = false;
        });
      }
    },
    async loadPlan(item) {
      // eslint-disable-next-line no-shadow
      const plan = item.plans.filter((plan) => (plan.id === item.planId))[0];
      // eslint-disable-next-line
      item.planName = plan.name;
    },
    checkCardNumber(item) {
      if (item.cardNumber === '' || item.cardNumber == null) {
        // eslint-disable-next-line
        item.selected = false;
      }
    },
    onClickAddPlan() {
      this.holder.plans.push(
        {
          financialGroupId: null,
          contractId: null,
          contracts: null,
          subContractId: null,
          subcontracts: null,
          eligibilityId: null,
          eligibilities: null,
          planId: null,
          plans: null,
          contractName: null,
          subcontractName: null,
          planName: null,
          cardNumber: null,
          validityDate: null,
          selected: false,
          registered: false,
          familyCode: null,
          class: '',
          benefitTypeCode: null,
        },
      );
    },
    onClickDelPlan(item) {
      if (item.selected) {
        this.customizeSnackbarMessage('error', 'O plano também foi removido da lista de planos do dependente!');
        this.scrollTo();
      }

      const elementPosition = this.holder.plans.indexOf(item);

      if (this.holder.plans.length > 1) {
        this.holder.plans.splice(elementPosition, 1);
      } else {
        this.holder.plans.splice(elementPosition, 1);

        this.onClickAddPlan();
      }
    },
    async getBeneficiaries() {
      const cpfRequest = this.dependent.document ? this.formatter.removeNonDigit(this.dependent.document) : null;
      const isCpfValid = cpfRequest ? cpfRequest.length === 11 : false;

      if (isCpfValid) {
        await this.getBeneficiaryByPayroll();
      } else {
        await this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPayroll() {
      try {
        const cpfRequest = this.formatter.removeNonDigit(this.dependent.document);
        const queryString = `?documentNumber=${cpfRequest}`;
        const response = await this.payrollService.FindAllByFilters(queryString);
        if (response.data.content && response.data.content.length > 0) {
          this.confirmPhysicalPersonDialog(response.data.content[0]);
        } else {
          await this.getBeneficiaryByPhysicalPerson();
        }
      } catch (error) {
        this.getBeneficiaryByPhysicalPerson();
      }
    },
    async getBeneficiaryByPhysicalPerson() {
      this.loadingSearchDependent = true;

      const personCpf = this.dependent.document ? this.dependent.document.replace(/\D/g, '') : null;
      const queryParameters = { cpf: personCpf };

      this.physicalPersonService.FindAllByFilters(queryParameters).then((response) => {
        if (response && response.data && response.data.length) {
          if (response.data.length === 1) {
            this.confirmPhysicalPersonDialog(response.data[0]);
          } else {
            this.physicalPersons = response.data;

            this.showDialogPhysicalPersons = true;
          }
        }
      }).finally(() => {
        this.loadingSearchDependent = false;
      });

      this.loadingSearchDependent = false;
    },
    confirmPhysicalPersonDialog(item) {
      this.showDialogPhysicalPersons = false;

      this.dependent.fullName = item.name ? this.formatter.formatToTitleCase(item.name) : null;
      this.dependent.motherName = item.mothersName ? this.formatter.formatToTitleCase(item.mothersName) : null;
      this.dependent.birthDate = item.birthDate ? this.formatter.formatDate(item.birthDate) : null;
      this.dependent.eventDate = item.eventDate ? this.formatter.formatDate(item.eventDate) : null;
      this.dependent.gender = (item.gender && item.gender.id) ? item.gender.id : item.gender ? item.gender : null;
      this.dependent.maritalStatus = (item.maritalStatus && item.maritalStatus.id) ? item.maritalStatus.id : item.maritalStatus ? item.maritalStatus : null;
      this.dependent.registrationComplement = item.registrationComplement ? item.registrationComplement : null;
      this.dependent.stateBirth = item.stateBirth && !item.stateBirth.uf ? item.stateBirth : item.stateBirth && item.stateBirth.uf ? item.stateBirth.uf : null;
      this.dependent.relationship = item.degreeKinship ? item.degreeKinship : null;
      this.dependent.cns = item.cns ? item.cns : null;
      this.dependent.dnv = item.dnv ? this.formatter.unmaskDnv(item.dnv) : null;
      this.dependent.identityDocumentCountry = item.identityDocumentCountry;
      this.dependent.identityDocumentState = item.identityDocumentState;
      this.dependent.identityDocumentIssuer = item.identityDocumentIssuer;
      this.dependent.identityDocumentEmissionDate = item.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(item.identityDocumentEmissionDate) : null;
      this.dependent.telephone = item.telephone;
      this.dependent.cellphone = item.cellphone;
      this.dependent.identityDocumentNature = item.identityDocumentNature;
      this.dependent.identityDocumentCountry = item.identityDocumentCountry;
      this.dependent.email = item.email;
    },
    async loadGenders() {
      await this.genderService.FindAll().then((response) => {
        if (response && response.data) {
          this.genders = response.data;
        }
      });
    },
    async loadMaritalStatus() {
      await this.maritalStatusService.FindAll().then((response) => {
        if (response && response.data) {
          this.maritalStatus = response.data;
        }
      });
    },
    async loadKiship() {
      await this.kinshipService.FindAll().then((response) => {
        if (response && response.data) {
          this.kinships = response.data.filter(kinship =>
                              kinship.id !== 22
                              || kinship.code !== 'Titular'
                              || kinship.description !== 'Titular');
          this.kinships.sort((a, b) => (a.description > b.description ? 1 : -1));
        }
      });
    },
    async loadHolderBeneficiaryId() {
      if (this.holder.beneficiaryContractedPlanId) {
        await this.beneficiaryContractedPlanService.FindById(this.holder.beneficiaryContractedPlanId).then((response) => {
          if (response && response.data) {
            this.holder.beneficiaryId = Number(response.data.beneficiary.id);
            this.holderAddress = response.data.beneficiary.physicalPerson.activeAddress;
          }
        });
      }
    },
    async loadPlans() {
      this.holder.plans = [];

      if (!this.$refs.isValidFormDependentInfo.validate()) {
	    this.documentsList = [];
        this.customizeSnackbarMessage("error", "Defina o campo Grau de Parentesco");
        return;
      }

      this.isSelectedAllPlans = false;
      this.resetAllDocuments();
      this.documents = [];
      this.dependent.documents = [];

      this.loadingDependentPlans = true;
      this.documentsList = [];

      const params = new URLSearchParams();
      if (this.holder.financialGroupId) {
        params.append('financialGroupIds', this.holder.financialGroupId);
      }
      if (this.holder.beneficiaryId) {
        params.append('beneficiaryId', this.holder.beneficiaryId);
      }
      params.append('insuredSituation', 'ACTIVE');

      if (this.isRHProtegido) {
        params.append('allowMovementByProtectedHR', 'true');
      }

      await this.beneficiaryContractedPlanService.HolderToCreateMovement(params).then((response) => {
        if (response && response.data) {
          const plans = response.data.content;

          plans.forEach(async (plan) => {

            const payload = [{
              birthDate: this.formatter.formatDateBRtoString(this.dependent.birthDate),
              contractId: Number(plan.contractId),
              cpf: this.dependent.document,
              financialGroupId: Number(plan.financialGroupId),
              holderCpf: plan.insuredCpf,
              holderName: plan.insuredName,
              name: this.dependent.fullName,
              registration: plan.registrationPlate,
              subContractId: Number(plan.subcontractId),
            }];

            // eslint-disable-next-line no-shadow
            await this.dependentService.CheckDependentThirteenRules(payload).then((response) => {
              const pl = {
                financialGroupId: plan.financialGroupId,
                contractId: plan.contractId,
                contractName: `${plan.benefitName} - ${plan.carrierName} - ${plan.contractPolicy} - ${plan.contractOwnerLegalName}`,
                contracts: null,
                subContractId: plan.subcontractId,
                subcontracts: null,
                eligibilityId: plan.eligibilityId,
                eligibilities: null,
                planId: plan.planId,
                plans: null,
                subcontractName: `${plan.subcontractName}`,
                planName: plan.planName,
                cardNumber: plan.insuredCardCode,
                validityDate: null,
                selected: false,
                registered: false,
                familyCode: plan.familyCode,
                class: '',
                benefitTypeCode: plan.benefitTypeCode,
                registrationPlate: plan.registrationPlate,
                contractPlanId: plan.contractPlanId,
              };


              this.loadCarrierByContractId(pl)

              if (response && response.data && response.data[0].id == null) {
                this.holder.plans.push(pl);
              } else {
                pl.registered = true;
                pl.selected = true;
                pl.validityDate = this.formatter.formatDate(plan.startDate);

                this.holder.plans.push(pl);
              }

              sessionStorage.setItem('dependentContractedPlan', JSON.stringify(this.holder.plans));
            });
          });
          this.loadCarriers(plans);
        }
      });

      this.loadingDependentPlans = false;
    },
    loadCarriers(plans) {
      this.carriers = [];

      plans.forEach((plan) => {
        this.contractService.FindById(plan.contractId).then((response) => {
          this.carriers.push(response.data);
          plan.benefitTypeCode = response.data.benefit_id;
        });
      });
    },
    loadCarrierByContractId(plan) {
      this.contractService.FindById(plan.contractId).then((response) => {
        if(response.data.carrier_id) {
          plan.carrierId = response.data.carrier_id.id;
        }
      });
    },
    selectAllPlans() {
      for (let plan of this.holder.plans) {
        // eslint-disable-next-line
        plan.selected = false;
        if (this.isSelectedAllPlans) {
          // eslint-disable-next-line
          plan.selected = true;

          if (this.isFreeMovement && (!plan.planId || !plan.cardNumber)) {
            // eslint-disable-next-line
            plan.selected = false;
          }
        } else if (plan.registered) {
          // eslint-disable-next-line
          plan.selected = true;
        }

        this.loadDocuments(plan);

        this.verifyPlanDocuments(plan);
      };
    },
    onClickBack() {
      this.redirectRouter('DependentInclusion', { isRHProtegido: this.isRHProtegido });
    },
    redirectToHome() {
      const name = this.isRHProtegido ? 'HomeRHProtegido' : 'HomeAllMenus';
      this.redirectRouter(name, { isRHProtegido: this.isRHProtegido });
    },
    loadDocuments(item) {
      if (item.selected !== true) {
        const planIndex = this.documentsList.findIndex(plan => plan.contractId === item.contractId);
        delete this.documentsVisibility[`UploadDocuments-#C${item.contractId}-#I${item.carrierId}`];

        if (planIndex !== -1) {
          this.documentsList.splice(planIndex, 1);
        }

        if (this.documentsList.length > 0) {
          this.startSearch = true;
        }

        return;
      }

      const route = this.$route.query;
      var docParams = {
        carrierId: item.carrierId,
        contractId: item.contractId,
        movementType: route.movementType,
        beneficiaryType: 'DEPENDENT',
        kinshipId: this.dependent.relationship,
        benefitTypeCode: item.benefitTypeCode ? item.benefitTypeCode.xipp_contract_record_type_code : null

      };

      this.documentsList.push({
        contractName: item.contractName,
        contractId: item.contractId,
        params: docParams,
        carrierId: item.carrierId
      });

      if (this.documentsList.length > 0) {
        this.startSearch = true;
      }
    },
    validateNumbersGreaterThanOne(value, id) {
      if (parseFloat(value) < 1) {
        document.getElementById(id).focus();
        switch (id) {
          case 'fieldCheckedWeight':
            this.controlWeight = true;
            this.controlHeight = false;
            break;
          case 'fieldCheckedHeight':
            this.controlHeight = true;
            this.controlWeight = false;
            break;
          default:
            this.controlHeight = false;
            this.controlWeight = false;
            break;
        }
      } else {
        this.controlHeight = false;
        this.controlWeight = false;
      }
    },
    async loadSessionStorage() {
      if (sessionStorage.getItem('contractedPlanInfo')) {
        const contractedPlanInfo = await JSON.parse(sessionStorage.getItem('contractedPlanInfo'));

        this.holder.beneficiaryContractedPlanId = contractedPlanInfo.contractPlanId ? contractedPlanInfo.contractPlanId : null;
        this.holder.financialGroupId = Number(contractedPlanInfo.financialGroupId);
        this.holder.name = this.formatter.formatToTitleCase(contractedPlanInfo.insuredName);
        this.holder.cpf = contractedPlanInfo.insuredCpf;
        this.holder.registrationPlate = contractedPlanInfo.registrationPlate;
        this.dependent.familyCode = contractedPlanInfo.familyCode;
      }
      this.isDisabledFamilyCode = !!this.dependent.familyCode;
    },
    conditionShowEventDate() {
      if (this.dependent.relationship && this.kinships && this.kinships.length > 0) {
        const relationshipSelected = this.kinships.find((kinship) => kinship.id === this.dependent.relationship);
        if(!this.formatter.isEmptyValue(relationshipSelected) && relationshipSelected !== null){
          switch (relationshipSelected.code) {
            case 'tutelado':
            case 'marido':
            case 'esposa':
            case 'conjuge':
            case 'cônjuge':
            case 'companheiro':
            case 'filhoadotivo':
            case 'enteado':
              return true;

            default: return false;
          }
        }
      }
      return false;
    },
    conditionGraceEventDate() {
      if (this.dependent.relationship && this.kinships && this.kinships.length > 0) {
        const relationshipSelected = this.kinships.find((kinship) => kinship.id === this.dependent.relationship);
        switch (relationshipSelected.code) {
          case 'filho':
          case 'filhouniversitario':
          case 'filhoinvalido':
            return true;

          default: return false;
        }
      }
      return false;
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
    verifySelectedPlans() {
      let selected = false;
      this.holder.plans.forEach((plan) => {
        if (!plan.registered && plan.selected) {
          selected = true;
        }
      });

      return selected;
    },
    scrollTo() {
      this.$refs.tops.scrollIntoView({ behavior: 'smooth' });
    },
    async onClickAddDependent(isContinue) {

      this.isContinue = isContinue;
      const isValidFormDependentInfo = this.$refs.isValidFormDependentInfo.validate();
      const isValidFormDependentDocs = this.$refs.isValidFormDependentDocs.validate();
      const isValidFormDependentPlans = this.$refs.isValidFormDependentPlans.validate();

      var isValidDocuments = true;

      Object.keys(this.$refs).forEach((refName) => {
        if (refName.startsWith('UploadDocuments-')) {
          if (this.$refs[refName].length > 0 && !this.$refs[refName][0].validate()) {
            isValidDocuments = false;
          };
      }});

      if(!isValidDocuments) {
        this.customizeSnackbarMessage('error', 'Confira os documentos do dependente.');
        return;
      }

      if (isValidFormDependentInfo && isValidFormDependentDocs && isValidFormDependentPlans) {
        if (this.verifySelectedPlans()) {
          this.dependent.eventDate = this.conditionShowEventDate() ? this.dependent.eventDate : null;
          const eventDateVerified = await this.verifyEventDate();
          if(eventDateVerified) {
            this.verifyGracePeriod();
          }
        } else {
          this.showAlertSelectPlan = true;
        }
      } else {
        this.customizeSnackbarMessage('error', 'Confira as informações de dependente e planos do dependente.');
        this.scrollTo();
      }
    },
    async verifyEventualDependent() {
      this.persistWithCriticism = !!this.isContinue;
      let isValidFormHolderInfo;
      let isValidFormHolderPlans;
      if (this.isFreeMovement) {
        isValidFormHolderInfo = this.$refs.isValidFormHolderInfo.validate();
        isValidFormHolderPlans = this.$refs.isValidFormHolderPlans.validate();
      }


      if (this.isFreeMovement) {
        if (isValidFormHolderInfo && isValidFormHolderPlans) {
          this.saveEventualDependent();
        } else {
          this.customizeSnackbarMessage('error', 'Confira as informações de titular e planos do titular.');
          this.scrollTo();
        }
      } else {
        const isHolder = await this.isHolder();

        if (isHolder) {
          this.openConfirmAddDependentModal();
        } else {
          this.saveEventualDependent();
        }
      }
    },
    verifyGracePeriod() {
      let hasGracePeriod = false;
      this.gracePeriods.forEach(element => {
        if(element.hasGracePeriod === true) {
          hasGracePeriod = true;
        }
      });
      if(hasGracePeriod) {
        this.validateGraceModal = true;
      } else {
        this.verifyEventualDependent();
      }
    },
    async isHolder() {
      let isHolder = false;

      if (this.dependent.document) {
        const insuredCpf = this.formatter.removeNonDigit(this.dependent.document);

        await this.beneficiaryService.FindAllByCpf(insuredCpf).then((response) => {
          isHolder = response && response.data.length > 0;
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao verificar se o beneficiário é titular!');
        });
      }

      return isHolder;
    },
    openConfirmAddDependentModal() {
      this.showAlertDependentHolder = true;
    },
    onClickconfirmAddDependent() {
      this.showAlertDependentHolder = false;
      this.saveEventualDependent();
    },
    formatterDocuments(dependent) {
      if (dependent.documents && dependent.documents.length > 0) {
        // eslint-disable-next-line no-param-reassign
        dependent.documents = dependent.documents.map((doc) => ({ id: doc.id }));
      }
    },
    formatterPlans(dependent) {
      // eslint-disable-next-line no-param-reassign
      dependent.plans = [];
      let eligibilityFound = null;

      this.holder.plans.forEach((plan) => {
        if (this.isFreeMovement) {
          const planFound = plan.plans.find((p) => p.id === plan.planId);
          eligibilityFound = planFound.eligibility_id;
        }

        if (!plan.registered && plan.selected) {
          let hasAcceptedGracePeriod = this.isAcceptedGracePeriod(plan.contractId);
          const documents = this.dependent.documents.filter(el => el.carrierId === plan.carrierId && el.contractId === plan.contractId);

          dependent.plans.push(
            {
              contract: plan.contractId ? String(plan.contractId) : null,
              eligibility: eligibilityFound ? String(eligibilityFound) : plan.eligibilityId ? String(plan.eligibilityId) : null,
              financialGroupId: plan.financialGroupId ? String(plan.financialGroupId) : null,
              plan: plan.planId ? String(plan.planId) : null,
              subcontract: plan.subContractId ? String(plan.subContractId) : null,
              validityDate: plan.validityDate ? this.formatter.formatDateBRtoString(plan.validityDate) : null,
              beneficiaryCard: plan.cardNumber ? String(plan.cardNumber) : null,
              familyCode: plan.familyCode ? String(plan.familyCode) : null,
              hasAcceptedGracePeriod: hasAcceptedGracePeriod,
              documents: documents,
              holderRegistrationPlate: plan.registrationPlate,
              beneficiaryContractedPlanId: plan.contractPlanId,

            },
          );
        }
      });
    },
    formatterDependent(dependent) {
      const formatEventDate = dependent.eventDate ? this.formatter.formatDateBRtoString(dependent.eventDate) : null;
      dependent.height = dependent.height ? Number(dependent.height) : '';
      dependent.weight = dependent.weight ? Number(dependent.weight) : '';
      dependent.document = this.formatter.removeNonDigit(dependent.document);
      dependent.birthDate = this.formatter.formatDateBRtoString(dependent.birthDate);
      dependent.eventDate = this.conditionShowEventDate() ? formatEventDate : null;
      dependent.dnv = dependent.dnv ? this.formatter.unmaskDnv(dependent.dnv) : '';
      dependent.skipDuplicityCheck = this.skipDuplicityCheck ? this.skipDuplicityCheck : false;
      dependent.identityDocumentEmissionDate = dependent.identityDocumentEmissionDate ? this.formatter.formatDateBRtoString(dependent.identityDocumentEmissionDate) : null;
      dependent.telephone = dependent.telephone ? dependent.telephone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;
      dependent.cellphone = dependent.cellphone ? dependent.cellphone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '') : null;

      if (this.isFreeMovement === false) {
        dependent.holderId = this.holder.beneficiaryId;
      }
    },
    formatterHolder(dependent) {
      dependent.holder = {
        cpf: this.formatter.removeNonDigit(this.holder.cpf),
        name: this.holder.name,
        registrationPlate: this.holder.registrationPlate,
        beneficiaryContractedPlanId: this.isFreeMovement === false ? this.holder.beneficiaryContractedPlanId : null,
      };
    },
    checkEmptyFields(item) {
      if (item && item.length > 0) {
        const updatedEntries = item.map((value) => {
          const updatedValue = { ...value };
          Object.entries(updatedValue).forEach(([key, val]) => {
            updatedValue[key] = val === '' ? null : val;
          });

          return updatedValue;
        });

        return updatedEntries;
      }

      return item;
    },
    clearForms() {
      this.violations = [];
      this.documents = [];
      this.documentsList = [];
      this.address = {};
      this.addressData = {};
      this.dependent = {
        document: null,
        fullName: null,
        birthDate: null,
        maritalStatus: null,
        gender: null,
        relationship: null,
        cns: null,
        dnv: null,
        motherName: null,
        height: null,
        weight: null,
        documents: [],
        eventDate: null,
        validityDate: null,
        stateBirth: null,
        plans: [],
        email: null,
        telephone: null,
        identityDocumentNature: null,
        identityDocumentNumber: null,
        identityDocumentEmissionDate: null,
        identityDocumentIssuer: null,
        identityDocumentState: null,
        identityDocumentCountry: null,
      };

      if (this.documentsList && this.documentsList.length > 0) {
        this.documentsList.forEach((document) => {
          if (document && document.attachments && document.attachments.length > 0) {
            for (let attachment of document.attachments) {
              attachment.file = null;
            }
          }
        });
      }

      this.physicalPersons = [];
      this.releaseMessageType = '';

      if (this.isFreeMovement) {
        this.holder.plans.forEach((plan) => {
          // eslint-disable-next-line no-param-reassign
          plan.selected = false;
          // eslint-disable-next-line no-param-reassign
          plan.validityDate = null;
        });
      } else {
        this.holder.plans = [];
      }
    },
    async saveEventualDependent() {
      this.overlay = true;
      this.violations = [];

      let dependent = { ...this.dependent };

      this.formatterDocuments(dependent);
      this.formatterPlans(dependent);
      this.formatterDependent(dependent);

      this.formatterHolder(dependent);

      dependent = this.formatter.validateEmptyValues(dependent);

      // eslint-disable-next-line prefer-destructuring
      dependent = this.checkEmptyFields([dependent])[0];

      let isAllMovementCreated = true;

      let criticsm = [];

      if (sessionStorage.getItem('criticismRules')) {
        criticsm = JSON.parse(sessionStorage.getItem('criticismRules')).filter((movement) => movement.classification && movement.classification === 'INTERNAL').map((movement) => movement.id);
      }

      dependent = lodash.cloneDeep({
        ...dependent,
        persistWithCriticism: this.persistWithCriticism ? this.persistWithCriticism : false,
        internalCriticisms: criticsm,
        address: this.address,
      });

      dependent = this.formatter.validateEmptyValues(dependent);

      delete dependent.documents;

      delete dependent.documents;

      let allWithStatusWaitingRulesApproval = false;

      await this.movementRecordService.InsertEventualDependents([{ ...dependent }], this.isFreeMovement).then((response) => {
        const { recordList } = response.data;
        allWithStatusWaitingRulesApproval = recordList.every((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS');
        this.waitingRulesApprovalIds = [];
        this.waitingRulesApprovalIds.push(...recordList.filter((movement) => movement.status === 'WAITING_APPROVAL_RULES_VALIDATIONS').map((movement) => movement.id));

        if (this.waitingRulesApprovalIds.length > 0) {
          const releaseMovementData = {
          beneficiaryName: dependent.fullName,
          movementType: 'Inclusão de dependente',
          };

          this.$refs.ReleaseMovementModal.open(releaseMovementData);
        } else {
          this.closeConfirmReturnModal();
        }
      }).catch((error) => {
        isAllMovementCreated = false;

        if (error.response.status === 422) {
          this.violations.push(...error.response.data.violations);

          if (!this.areAllowedToCriticize()) {
            this.overlay = false;
            return;
          }

          this.$refs.CriticismHandlingModal.open();
        } else {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao criar a movimentação');
        }
      });

      this.overlay = false;
    },
    areAllowedToCriticize() {
      if (!this.hasPermission('sdi_mov_prosseguir_critica') && this.violations && this.violations.length > 0) {
        this.$refs.SnackbarCustomize.open('error', 'Erro de validação de regras no preenchimento do formulário');
        return false;
      }

      return true;
    },
    clearDocument(item) {
      const documentIndex = this.dependent.documents.findIndex(el => el.id === item.fileId);

      if (documentIndex >= 0) {
        this.dependent.documents.splice(documentIndex, 1);
      }

      item.fileId = null;
    },
    observerDocumentData(data, document) {
      this.documents = data;

      if(!this.dependent.documents) {
        this.dependent.documents = [];
      }

      if(this.documents) {
        this.documents.forEach(doc => {
          if(!this.dependent.documents.some(item => item.id === doc.id)) {
            this.dependent.documents.push({
              id: doc.id,
              name: doc.titleDocument,
              file: doc.file,
              type: doc.type,
              carrierId: document.carrierId,
              contractId: document.contractId,
            });
          }
        });
      }

    },
    cleanThisDocuments(document) {
      this.dependent.documents = this.dependent.documents.filter((doc) => doc.carrierId !== document.carrierId);
    },
    uploadDocument(item, index) {
      if (item) {
        if ((item.file && !this.rule.isValidFileSize(item.file.size)) || (item.file && item.file.type && (item.file.type !== 'application/pdf' && item.file.type !== 'image/jpg' && item.file.type !== 'image/jpeg' && item.file.type !== 'image/png' && item.file.type !== 'image/bmp' && item.file.type !== 'image/tiff' && item.file.type !== 'image/webp'))) {
          return;
        }

        if (item.file !== null && !!item.file) {
          this.validationUploadDocument(item, index);
        }
      }
    },
    validationUploadDocument(item, index) {
      this.waitingDocumentUpload = true;
      this.documentService.UploadDocument(item.id, item.file).then((response) => {
        this.waitingDocumentUpload = false;
        const validation = this.dependent.documents.filter((element) => element.id === response.data);
        if (validation.length === 0) {
          this.pushUploadDocument(response, item, index);
        }
      }).catch(() => {
        this.waitingDocumentUpload = false;
      });
    },
    pushUploadDocument(response, item, index) {
      this.dependent.documents.push({
        id: response.data,
        name: item.name,
        file: item.file,
        type: item.file.type,
        carrierId: item.carrierId,
        contractId: item.contractId,
      });

      const documentsIndex = this.documentsList.findIndex(el => el.carrierId === item.carrierId);

      this.documentsList[documentsIndex].attachments[index].fileId = response.data;
    },
    closeDialogInsertListMovements() {
      this.$refs.ReleaseMovementModal.close();

      const releaseMovementData = {
        beneficiaryName: this.dependent.fullName,
        movementType: 'Inclusão de dependente',
      };

      this.$refs.TransitionMovementModal.open(releaseMovementData);
    },
    backToInclusionSearchHolderPage() {
      this.$refs.TransitionMovementModal.close();
      this.redirectRouter('DependentInclusion', { isRHProtegido: this.isRHProtegido }, { creation: true })
      this.clearForms();
    },
    goToMovementPage() {
      this.setSearchParams({});
      this.redirectRouter('Movement', { isRHProtegido: this.isRHProtegido }, { movement: 'movement' });
      this.clearForms();
    },
    closeAllDialogInsertListMovements() {
      this.$refs.ReleaseMovementModal.close();

      const releaseMovementData = {
        beneficiaryName: this.dependent.fullName,
        movementType: 'Inclusão de dependente',
      };

      this.$refs.TransitionMovementModal.open(releaseMovementData);
    },
    addNewDependent() {
      this.$refs.TransitionMovementModal.close();

      this.clearForms();
    },
    async loadAuthorityMovement() {
      this.hasAuthorityReleaseMovement = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_painel_movimentacoes_cadastrais_liberar_movimentacao' });
    },
    async updateStatus() {
      this.$refs.ReleaseMovementModal.close();

      if (this.hasAuthorityReleaseMovement && this.waitingRulesApprovalIds.length > 0) {
        const request = {
          ids: [...this.waitingRulesApprovalIds],
          status: 'VALID',
        };

        await this.movementRecordService.UpdateStatusFromMultipleMovements(request).then(() => {
          this.closeConfirmReturnModal();
          this.customizeSnackbarMessage('success', 'Movimentação liberada com sucesso');
        }).catch(() => {
          this.releaseMessageType = this.errorReleaseMessageType;
          this.showReleaseInfo = true;
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao liberar a movimentação');
        });
      } else {
        this.closeDialogInsertListMovements();
      }
    },
    closeConfirmReturnModal() {
      this.releaseMessageType = this.successReleaseMessageType;
      this.$refs.ReleaseMovementModal.close();

      const releaseMovementData = {
        beneficiaryName: this.dependent.fullName,
        movementType: 'Inclusão de dependente',
      };

      this.$refs.TransitionMovementModal.open(releaseMovementData);
    },
    async getValidateGracePeriod(params) {
      this.gracePeriods = [];
      await this.contractService.ValidateGracePeriod(params).then((response) => {
        if(response) {
          response.data.forEach(element => {
            if(element.hasGracePeriod === true) {
              this.gracePeriods = response.data;
            }
          });
        }
      }).catch(() => {
        this.customizeSnackbarMessage('error', 'Ocorreu um erro ao verificar a carência do beneficiário')
      });
    },
    isAcceptedGracePeriod(contract) {
      let result = null;
      for (let grace of this.gracePeriods) {
        if(grace.parentContractId.toString() === contract && grace.benefitId !== null) {
          result = this.hasAcceptedGracePeriod;
        }
      }

      return result;
    },
    async verifyEventDate() {
      let validEventDate = false;
      let eventDate = null;
      this.invalidGraceContractNames = [];

      if(this.dependent.eventDate !== null) {
        eventDate = this.dependent.eventDate;
        validEventDate = true;
      } else if (this.conditionGraceEventDate() &&
                  this.dependent.birthDate !== null &&
                  this.dependent.birthDate.trim().length > 0) {
        eventDate = this.dependent.birthDate;
        validEventDate = true;
      } else if (this.dependent.eventDate === null && !this.conditionGraceEventDate()) {
        validEventDate = true;
      } else {
        validEventDate = false;
      }

      let params = [];
      this.holder.plans.forEach((plan) => {
        if (!plan.registered && plan.selected) {
          if(eventDate !== null) {
            params.push({
              parentContractId: plan.contractId,
              eventDate: this.formatter.formatDateBRtoString(eventDate),
              startDate: this.formatter.formatDateBRtoString(plan.validityDate),
            })
          }
          this.invalidGraceContractNames.push(this.getXippCommercialName(plan.contractId));
        }
      });

      if(!validEventDate) {
        this.alertGraceModal = true;
      } else {
        this.hasAcceptedGracePeriod = null;
        await this.getValidateGracePeriod(params);
      }

      return validEventDate;
    },
    getXippCommercialName(contractId) {
      let contract = this.carriers.find(function(carrier) {
        return (carrier.id.toString() === contractId)
      })

      return contract.carrier_id.xipp_commercial_name;
    },
    closeAlertGraceModal() {
      this.alertGraceModal = false;
    },
    closeValidateGraceModal() {
      this.validateGraceModal = false;
    },
    confirmGracePeriod() {
      this.hasAcceptedGracePeriod = true;
      this.closeValidateGraceModal();
      this.verifyEventualDependent();
    },
    nonConfirmGracePeriod() {
      this.hasAcceptedGracePeriod = false;
      this.closeValidateGraceModal();
      this.verifyEventualDependent();
    },
  },
  async created() {
    this.formatter = new Formatters();
    this.rule = new Rules();
    this.genderService = new GenderService();
    this.maritalStatusService = new MaritalStatusService();
    this.beneficiaryContractedPlanService = new BeneficiaryContractedPlanService();
    this.physicalPersonService = new PhysicalPersonService();
    this.kinshipService = new KinshipService();
    this.movementRecordService = new MovementRecordService();
    this.documentService = new DocumentService();
    this.documentTypeService = new DocumentTypeService();
    this.beneficiaryService = new BeneficiaryService();
    this.dependentService = new DependentService();
    this.financialGroupService = new FinancialGroupService();
    this.contractService = new ContractService();
    this.contractEligibilityService = new ContractEligibilityService();
    this.contractProductService = new ContractProductService();
    this.userUtils = new UserUtils();
    this.payrollService = new PayrollService();
    this.carrierService = new CarrierService();
    this.movementDocumentService = new MovementDocumentService();
  },
};
</script>

<style scoped>
  .btn-dialog {
    width: 200px;
    font-size: 16px;
  }
</style>
